:root {
    --main-color: 124, 58, 237;
    --secondary-color: 218, 98, 196;
    --dark-color: 27, 31, 35;
    --main-color-gradient: linear-gradient(45deg, rgb(var(--main-color)), rgb(var(--secondary-color)) 30%, white 60%);
}

* {
    margin: 0;
}

html {
    font-family: system-ui, sans-serif;
    background-color: #F6F6F6;
}

body {
    margin: 0;
}

code {
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.content {
    padding: 5em 1.5em 1.5em;
    align-items: center;
    display: flex;
    justify-content: center;
}

.gradient-color {
    background-image: var(--main-color-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400%;
}
