.Header {
    top: 0;
    position: fixed;
    width: 100%;
    line-height: 1.6;
    border-bottom: 3px solid rgba(var(--main-color), 25%);
    background-color: white;
    border-top: none;
    border-radius: 0.4em;
}

.Header a {
    text-decoration: none;
}

.Header h1 {
    font-size: 2em;
}

.Header .items {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 2%;
    margin-right: 2%;
}

.Header .login {
    display: flex;
    align-items: center;
    margin-left: 2%;
}

.Header .items *:first-child {
    margin-inline-end: auto;
}

.Header .logo {
    display: flex;
    align-items: center;
}

.Header .page {
    color: rgb(var(--secondary-color));
    padding: 0 10px;
}

.Header .page p {
    font-size: 1.2em;
    transition: color 0.6s;
}

.Header .page:hover {
    color: rgb(var(--main-color));
    transition: color 0.6s;
}

.Header .active {
    color: rgb(var(--main-color));
}
